exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-about-about-comp-js": () => import("./../../../src/pages/components/About/AboutComp.js" /* webpackChunkName: "component---src-pages-components-about-about-comp-js" */),
  "component---src-pages-components-contact-faq-comp-js": () => import("./../../../src/pages/components/Contact/FaqComp.js" /* webpackChunkName: "component---src-pages-components-contact-faq-comp-js" */),
  "component---src-pages-components-contact-form-get-quote-js": () => import("./../../../src/pages/components/Contact/formGetQuote.js" /* webpackChunkName: "component---src-pages-components-contact-form-get-quote-js" */),
  "component---src-pages-components-contact-form-say-hi-js": () => import("./../../../src/pages/components/Contact/formSayHi.js" /* webpackChunkName: "component---src-pages-components-contact-form-say-hi-js" */),
  "component---src-pages-components-footer-footer-comp-js": () => import("./../../../src/pages/components/Footer/FooterComp.js" /* webpackChunkName: "component---src-pages-components-footer-footer-comp-js" */),
  "component---src-pages-components-hero-hero-comp-js": () => import("./../../../src/pages/components/Hero/HeroComp.js" /* webpackChunkName: "component---src-pages-components-hero-hero-comp-js" */),
  "component---src-pages-components-navbar-navbar-comp-js": () => import("./../../../src/pages/components/Navbar/NavbarComp.js" /* webpackChunkName: "component---src-pages-components-navbar-navbar-comp-js" */),
  "component---src-pages-components-stack-service-js": () => import("./../../../src/pages/components/Stack/Service.js" /* webpackChunkName: "component---src-pages-components-stack-service-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

